.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    opacity: 0;
    animation: fadeIn 0.2s forwards;
}

.about-page, 
.contact-page {
    .text-zone {
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
        h1 {
            font-size: 3.5rem;
            font-family: 'Coolvetica', sans-serif;
            font-weight: 400;
            color: #da1710;
            margin: 0;
            padding: 0;
            position: relative;
            .text-animate-hover:hover {
                color: #fff;
            }
        }
        .flex {
            display: flex;
            align-items: center;
            justify-content: flex-start;   
            margin: 0 0 40px 0;         
        }
        img {
            position: relative;
            border-radius: 50%;
            width: 60px;
            height: auto;
            margin: 0 20px 0 0;
            animation: fadeInLeft 0.6s;
        }
    }
}

p {
    font-size: 1.4rem;
    font-family: sans-serif;
    font-weight: 300;
    color: #fff;
    margin: 0 0 10px 0;
    min-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
        animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
        animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
        animation-delay: 1.3s;
    }
}


@media only screen and (max-width: 850px) {
    body {
        .container {
            height: 100%;
        }
        .nav-bar {
            z-index: -1;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3 ease-in-out;
        }
        .mobile-menu {
            position: absolute;
            top: 10px;
            left: 15px;
            z-index: 4;
            font-size: 2.8rem;
            z-index: 30;
            svg {             
                color: #da1710;
            }
            &.close {
                pointer-events: none;
            }
        }
        .mobile.nav-bar {
            z-index: 30;
            opacity: 1;
            pointer-events: all;
        }
        .home-page,
        .about-page {
            .text-zone {
                position: absolute;
                top: auto;
                left: 0;
                bottom: 50px;
                transform: translateY(0);
                width: 100%;
                vertical-align: middle;
                text-align: center;
                margin: 0 auto;
                h1 {
                    font-size: 3rem;
                    img {
                        width: 35px;
                    }
                }
                
            }
            .home-logo {
                z-index: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 15%;
                right: 0;
                margin: auto;
                opacity: 0;
            }
        }
        
        .contact-page.flex {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .text-zone {
                width: 90%;
                text-align: center;
                margin: 0 auto;
                position: relative;
                top: 40px;
                left: 0;
                h1 {
                    font-size: 3rem;
                    img {
                        width: 35px;
                    }
                }
            }
            .map-zone {
                position: absolute;
                top: auto;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 280px
            }
            .leaflet-popup-content-wrapper {
                background-color: #585C72;
                text-align: center;
                width: 110px;
                height: 170px;
                img {
                    width: 80px;
                }
                h3 {
                    font-size: 1rem;
                }
            }
        }
        .about-page {
            .text-zone {
                z-index: 40;
                width: 90%;
                margin-left: 5%;
            }
        }
        .cube-container {
            width: 90%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 5%;
            z-index: 0;
            .cube {
                width: 300px;
                height: 300px;
                position: absolute;
                top: 0;
                left: 0;
                transform-style: preserve-3d;
                animation: rotate 20s linear infinite;
                overflow: visible;
            }
                .cube-spinner {
                    transform-origin: 150px 150px 0;
                    margin-left: calc(50% - 150px);
                    div {
                        width: 300px;
                        height: 300px;
                        font-size: 35px;
                        padding: 2px;
                    }
                    .cube-face-1 {
                        transform: translateZ(150px); 
                    }
                    .cube-face-2 {
                        transform: rotateY(90deg) translateZ(150px); 
                    }
                    .cube-face-3 {
                        transform: rotateY(90deg) rotateX(90deg) translateZ(150px);
                    }
                    .cube-face-4 { 
                        transform:  rotateY(180deg) rotateZ(90deg) translateZ(150px);
                    }
                    .cube-face-5 {
                        transform: rotateY(-90deg) rotateZ(90deg) translateZ(150px);
                    }
                    .cube-face-6 {
                        transform: rotateX(-90deg) translateZ(150px);
                    }
                }

        }
    }
  }