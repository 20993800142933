.contact-page.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.contact-form {
    width: 100%;
    margin-top: 20px;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
            width: 100%;
            display: block;
            margin: 0 0 20px 0;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            input, textarea {
                width: calc(100% - 20px);
                padding: 10px;
                border: 2px solid #585C72;
                border-radius: 5px;
                font-size: 1.2rem;
                font-family: sans-serif;
                font-weight: 300;
                color: #fff;
                background-color: #585C72;
                &:focus {
                    outline: none;
                    border: 2px solid #da1710;
                }
                &::placeholder {
                    color: #fff;
                }
            }
            input.form-button {
                -webkit-appearance: none;
                padding: 10px 0;
                width: 100%;
                &:hover {
                    background-color: #da1710;
                    border: 2px solid #da1710;
                }
            }
        }
        li.half {
            width: 47%;
        }
    }
}

.map-zone {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    .map-wrap {
        background-color: #585C72;
        width: 100%; 
        height: 100%;
        position: relative;
        
    }
}

.leaflet-popup-content-wrapper {
    background-color: #585C72;
    text-align: center;
    width: 220px;
    height: 350px;
    img {
        width: 194px;
        height: auto;
        margin: 5px auto 0 auto;
        position: relative;
        left: -5px;
    }
    h3 {
        margin: 10px 0;
        padding: 0;
        font-size: 2.5rem;
        font-weight: 400;
        font-family: 'La Belle Aurore', cursive;
        color: #fff;
        text-align: center;
        position: relative;
        left: -5px;
        line-height: 1;
    }
}
.leaflet-popup-tip {
    background-color: #585C72;
}
.leaflet-container a.leaflet-popup-close-button {
    color: #fff;
    &:hover {
        color: #da1710;
    }
}
.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    animation: backInRight 1s 1.5s;
    animation-fill-mode: forwards;
}