.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        max-height: 90%;
        z-index: 1;
    }
    h1 {
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica', sans-serif;
        font-weight: 400;
        img {
            position: relative;
            top: 1px;
            width: 60px;
            height: 100%;
            opacity: 0;
            animation: rotateIn 1s linear both;
            animation-delay: .5s;     
            margin-left: 10px;       
        }
    }
    h2 {
        color: #8d8d8d;
        font-size: 16px;
        margin-top: 20px;
        font-family: sans-serif;
        margin-top: 20px;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }
    .button {
        display: inline-block;
        margin-top: 20px;
        color: #da1710;
        font-size: 16px;
        font-family: sans-serif;
        letter-spacing: 4px;
        text-decoration: none;
        padding: 10px 20px;
        border: 1px solid #da1710;
        border-radius: 3px;
        animation: fadeIn 1s 2.1s backwards;
        white-space: nowrap;
        transition: all 0.3s ease-out;
        font-weight: bold;
        &:hover {
            background: #da1710;
            color: #fff;
        }
    }
}