.nav-bar {
    background-color: #f3f4f6;
    height: 100%;
    width: 60px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    min-height: 500px;
    transition: 0.5s;
    .logo {
       padding: 8px 0;
        img {
            display: block;
            margin: 20px auto;
            height: auto;
            width: calc(100% - 16px);
        }
    }
    nav {
        display: block;
        text-align: center;
        position: absolute;
        top: 50%;
        height: 210px;
        margin-top: -120px;
        width: 100%;
        a {
            font-size: 22px;
            color: #4d4d4d;
            display: block;
            line-height: 51px;
            position: relative;
            text-decoration: none;
            i {
                transition: all 0.3 ease-out;
            }
            &:hover {
                color: #da1710;
                svg {
                    opacity: 0;
                }
                &:after {
                    opacity: 1;
                }
            }
            &:after {
                content: '';
                font-size: 8px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
                font-weight: bold;
            }
            &:first-child {
                &:after {
                    content: 'HOME';
                }
            }
        }
        a.about-link {
            &:after {
                content: 'ABOUT';
            }
        }
        a.portfolio-link {
            &:after {
                content: 'PORTFOLIO';
            } 
        }
        a.contact-link {
            &:after {
                content: 'CONTACT';
            }
        }
        a.active {
            svg {
                color: #da1710;
            }
        }
    }
    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        li {
            a{
                display: block;
                color: #4d4d4d;
                font-size: 15px;
                line-height: 16px;
                text-decoration: none;
                transition: all 0.3 ease-out;
                margin: 5px 0;
                &:hover svg {
                    color: #da1710;
                }
            }
        }
    }
}

